export function getStore(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (e) {
    console.log('获取缓存失败', e)
  }
}

export function setStore(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.log('设置缓存失败', e)
  }
}

export function removeStore(key) {
  window.localStorage.removeItem(key)
}

export function clearStore() {
  window.localStorage.clear()
}

export default {
  getStore,
  setStore,
  removeStore,
  clearStore,
}

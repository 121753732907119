<template>
  <div class="image-collection-wrap">
    <div class="main">
      <van-uploader
        id="def"
        v-model="images"
        :accept="accept"
        :upload-text="uploadText"
        :max-count="maxCount"
        :before-read="checkImages"
        :after-read="loadedImages"
        :disabled="isDisabled"
        multiple
        :class="{'small-btn': btnSize === 'small', 'plus-btn': btnSize === 'plus'}"
        :preview-options="{
          closeable: true,
        }"
        result-type="file"
        @click-preview="filePreview"
        @delete="deleteImage"
      >
        <template #preview-cover="{ file, statusCode, status }">
          <div v-if="file.saveStatusTxt" class="preview-cover van-ellipsis">{{ file.saveStatusTxt }}</div>
          <div 
            v-if="status === 'uploading' || status === 'failed'" 
            class="bg-color" 
            :class="{'red-color': statusCode === 4, 'blue-color': statusCode === 3}"
          ></div>
        </template>
        <van-button v-if="!isDisabled && isShowAddBtn" icon="plus" class="add-btn"></van-button>
        <div class="disabled-text" v-if="isDisabled">{{ disabledText }}</div>
      </van-uploader>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { compressFileImage } from "@/tools/image-compress";
import { isEmptyFunction } from '@/tools/utils'
import { sentryReportError, sentryReportMessage } from '@/tools/sentry'
import axios from "axios";
import { getMediaDimensions, checkFileOverSize, getFileType } from "../shared";
import { FILE_TYPE_IMAGE_DESC, FILE_TYPE_OTHER_DESC, FILE_TYPE_VIDEO_DESC, UPLOAD_FILE_UNIQUEID_LIST, SYSTEMTIME_DURATION, MAX_IMAGE_SIZE, MAX_VIDEO_SIZE } from '@/constant'
import { getStore } from '@/tools/storage'
import { getSystemTime } from '@/apis'

const ZIP_FILE_SIZE = 1024 * 1024 * 5
const START_UPLOAD_CODE = 1 // 开始上传的状态
const UN_START_UPLOAD_CODE = 0 // 未开始上传的状态
const UPLOAD_FAILED_CODE = -1 // 上传失败的状态
const COMPELTE_UPLOAD_CODE = 2 // 完成上传的状态
const UN_RECORD_AFTER_TASK = 3 // 录制时间未在任务开始时间之后
const UN_RECORD_AT_RIGHT_TIME = 4 // 录制时间超过选择文件时获取的服务器时间
const MIN_IMG_WIDTH = 400 // ocr 识别最小宽度400
const MAX_UPLOADING_COUNT = 2 // 同时请求上传的数量

export default {
  name: "ImageCollection",
  props: {
    jobId: {
      type: [Number, String],
      default: '',
    },
    taskId: {
      type: [Number, String],
      default: '',
    },
    // 可支持上传的文件后缀
    accept: {
      type: String,
      default: "",
    },
    getFile: {
      type: Function,
      default: () => {},
    },
    // 校验文件格式方法
    checkFile: {
      type: Function,
      default: () => {}
    },
    // 最大上传数量
    maxCount: {
      type: Number,
    },
    // 是否校验重复上传
    checkRepeat: {
      type: Boolean,
      default: false,
    },
    // 是否校验视频重复上传
    checkVideoRepeat: {
      type: Boolean,
      default: false,
    },
    // 是否校验不同模块之间的相同文件上传
    checkModuleRepeat: {
      type: Boolean,
      default: false,
    },
    // 上传至阿里云配置
    uploadOptions: {
      type: Object,
      default: () => {},
    },
    // 上传文件名称后缀
    suffix: {
      type: String,
      default: "",
    },
    // 上传框的提示文案
    uploadText: {
      type: String,
      default: "图片大小不超过50M，视频大小不超过500M",
    },
    // 上传按钮大小
    btnSize: {
      type: String,
      default: "",
    },
    // 任务开始时间
    jobStartTime: {
      type: Number,
      default: 0
    },
    // 是否需要去校验上传图片、视频的创建时间是否合理
    isCheckTime: {
      type: Boolean,
      default: false,
    },
    //是否校验上传图片创建时间是否合理
    isCheckVideoTime: {
      type: Boolean,
      default: false,
    },
    // 图片类型
    imageType: {
      type: [Number, String],
      default: ''
    },
    // 是否展示上传按钮
    isShowAddBtn: {
      tpye: Boolean,
      default: false,
    },
    // 是否禁止上传
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // 禁止上传文案
    disabledText: {
      type: String,
      default: '禁止上传'
    },
    // 用于C端活动提单页上传校验
    invalidNum: {
      type: Number,
      default: 0,
    },
    // 限制图片最大size
    maxImageSize: {
      type: Number,
      default: MAX_IMAGE_SIZE
    },
    // 限制视频最大size
    maxVideoSize: {
      type: Number,
      default: MAX_VIDEO_SIZE
    },
    // 是否去校验图片的宽度
    isCheckWidth: {
      type: Boolean,
      default: true
    },
    isUploadFile: {
      type: Boolean,
      default: false,
    },
    // 是否限制视频上传数量
    videoLimit: {
      type: Boolean,
      default: false,
    },
    // 视频限制上传数量
    videoLimitCount: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      images: [],
      zipingCount: 0, // 正在压缩的图片数量
      uploadingConut: 0,
      CHAIN_UID_MAP: Object.create(null),
    };
  },
  watch: {
    images(newVal) {
      this.$emit("getImagesList", newVal);
    },
  },
  mounted() {},
  destroyed() {
    this.CHAIN_UID_MAP = Object.create(null);
  },
  methods: {
    // 校验所有图片/视频是否校验合法，若存在校验不通过的图片/视频，则返回false与错误原因
    clearImages() {
      this.images = [];
    },
    /**
     * @description: 校验上传文件的格式
     * @param {*} file 文件
     * @return {*} boolen 
     */    
    checkFileExtension(file) {
      const { name } = file
      const fileExtension = name.slice(name.lastIndexOf('.') + 1);
      return this.accept.indexOf(fileExtension) > -1 
    },
    // 点击提交时校验已选择上传的文件列表
    async checkFileValid() {
      const failedList = []; // 上传失败列表
      for (let i = 0; i < this.images.length; i++) {
        const file = this.images[i];
        if (this.isUploadFile) {
          if (!this.checkFileExtension(file.file)) {
            return {
              valid: false,
              message: "文件格式错误",
            };
          }
        } else {
          const type = getFileType(file.file);
          if (type === FILE_TYPE_OTHER_DESC) {
            return {
              valid: false,
              message: "文件格式不规范",
            };
          }
        }
        
        const isOverSize = checkFileOverSize(file, this.maxImageSize, this.maxVideoSize);
        if (isOverSize) {
          return {
            valid: false,
            message: "文件大小超出限制",
          };
        }
        if (this.isCheckTime || (this.isCheckVideoTime && /video/.test(file.file.type))) {
          // 如果文件创建时间小于任务开始时间，不合格
          if (this.checkCreateTime(UN_RECORD_AFTER_TASK, file.lastModified)) {
            return {
              valid: false,
              message: `文件创建时间早于任务开始时间`
            }
          }
          // 如果创建时间大于当前时间，不合格
          if (this.checkCreateTime(UN_RECORD_AT_RIGHT_TIME, file.lastModified)) {
            return {
              valid: false,
              message: `文件创建时间大于当前时间`
            }
          }
        }
        // 如果需要校验图片宽度的话
        if (this.isCheckWidth) {
          try {
            const { width } = await getMediaDimensions(file, file.file.type);
            if (width && width < MIN_IMG_WIDTH) {
              return {
                valid: false,
                message: `文件宽度小于${MIN_IMG_WIDTH}px`,
              };
            }
          } catch (err) {
            return {
              valid: false,
              message: `文件宽度小于${MIN_IMG_WIDTH}px`,
            }
          }
        }
        // 如果有文件校验函数，则执行函数
        if (!isEmptyFunction(this.checkFile)) {
          const checkFile = await this.checkFile(file)
          if (!checkFile.valid) {
            return checkFile
          }
        }

        if (file.status === 'failed') {
          failedList.push(i + 1)
        }
      }
      if (failedList.length) {
        return {
          valid: false,
          message: `第${failedList.join("、")}张文件上传失败，请删除或重新上传`,
          messageType: "full",
        };
      }
      return {
        valid: true,
      };
    },
    // 获取服务器时间
    async getOriginSystemTime() {
      try {
        const res = await getSystemTime()
        if (res.status === '200') {
          return res.data.time || res.timeStamp || null
        }
      } catch(e) {
        return null
      }
    },
    // 校验系统与服务器的时间
    async checkSystemTime() {
      const originSystemTime = await this.getOriginSystemTime()
      if (!originSystemTime) return '获取服务器时间失败，请稍后重试'
      const systemTime = new Date().getTime()
      if (Math.abs(Number(originSystemTime) - systemTime) > SYSTEMTIME_DURATION) {
        return '您上传文件的录制时间有误，请将手机时间调整为“北京时间”或“自动”后，重新录制上传。'
      }
      return ''
    },
    // 文件预校验
    async checkImages(files) {
      if (!this.uploadOptions) {
        Toast.fail({
          duration: 4000,
          message: "上传签名获取失败，请刷新重试",
        });
        return false;
      }
      let flag = true;
      let msg = ""; 
      const checkFiles = Array.isArray(files) ? files : [files];
      let videoNum = 0
      const images = this.checkModuleRepeat ? this.$parent.getImgsList() : this.images
      checkFiles.forEach((file) => {
        const { lastModified, size, type} = file;
        const fileIsVideo = /video/.test(type)
        if (fileIsVideo) {
          videoNum ++
        }
        if(this.checkRepeat || (this.checkVideoRepeat && fileIsVideo)) {
          const uniqueId = `${lastModified}_${size}`;
          const fileUniqueLists = getStore(UPLOAD_FILE_UNIQUEID_LIST) || []; 
          // 只保留 最后更新时间_大小
          const fileUniqueList = fileUniqueLists.map(item => {
            const splitList = item.split('_')
            return `${splitList[0]}_${splitList[1]}`
          })
          if (
            Array.isArray(fileUniqueList) &&
            fileUniqueList.includes(uniqueId)
          ) {
            msg = "选择的文件在之前任务中上传过，请重新选择";
            flag = false;
          }
          if (flag) {
            for (let i = 0; i < images.length; i++) {
              const splitImg = images[i].uniqueId.split('_')
              const imgId =  `${splitImg[0]}_${splitImg[1]}`
              if ( imgId === uniqueId) {
                msg = `选择的文件与第${i + 1}个相同，请重新选择`;
                flag = false;
                break;
              }
            }
          }
        }  
      });
      // 校验系统时间和服务器时间
      if (this.isCheckTime) {
        const checkTimeStr = await this.checkSystemTime()
        if (checkTimeStr) {
          flag = false
          msg = checkTimeStr
        }
      }
      // 检验是否超出video数量
      if (this.videoLimit) {
        const videoList = images.filter(item => item.type === FILE_TYPE_VIDEO_DESC)
        if (videoList.length >= this.videoLimitCount && videoNum > 0) {
          flag = false
          msg = "活动模块只能上传一个视频"
        }
      }
      if (msg) {
        Toast.fail({
          duration: 4000,
          message: msg,
        });
      }
      return flag ? Promise.resolve(files) : Promise.reject()
    },
    loadedImages() {
      this.$emit("file-change");
      this.uploadImgs();
    },
    deleteImage() {
      this.$emit('file-change')
      this.uploadImgs()
    },
    // 判断文件创建时间
    checkCreateTime(type, lastModified) {
      const now = new Date().getTime()
      if (type === UN_RECORD_AFTER_TASK) {
        return lastModified < this.jobStartTime
      } else if (type === UN_RECORD_AT_RIGHT_TIME) {
        return lastModified > now
      }
    },
    setFileInfo(file, message, code) {
      file.message = message
      file.status = 'failed'
      file.statusCode = code
    },
    async uploadImgs() {
      const overSizeFiles = []
      const invalidFiles = []
      const unAfterTaskFiles = []
      const unBeforeNowFiles = []
      const nowTime = Date.now()
      for (let i = 0; i < this.images.length; i++) {
        const file = this.images[i];
        if (!file.status) {
          const { lastModified, size, type } = file.file;
          // 文件名称后面加时间戳
          file.file = new File([file.file], file.file.name.replace('.', `_${Date.now()}${this.suffix}.`), {
            type: file.file.type,
          })
          file.lastModified = file.file.lastModified // 存最后修改时间
          if (lastModified && size) {
            file.uniqueId = `${lastModified}_${size}_${this.taskId}_${this.invalidNum}`
            file.lastModified = lastModified
          }
          file.chooseFileTime = nowTime
          file.retryTimes = 0
          file.message = '等待中...'
          file.status = 'uploading'

          const isCreateTimeValid = Math.abs(file.chooseFileTime - file.lastModified) > 1000
          if ((this.isCheckTime || (this.isCheckVideoTime && /video/.test(type))) && isCreateTimeValid) {
            // 如果文件创建时间小于任务开始时间，不合格
            if (this.checkCreateTime(UN_RECORD_AFTER_TASK, file.lastModified)) {
              this.setFileInfo(file, '创建时间早于任务开始时间', UN_RECORD_AFTER_TASK)
              unAfterTaskFiles.push(file.file.name)
              continue
            }
            // 如果创建时间大于当前时间，不合格
            if (this.checkCreateTime(UN_RECORD_AT_RIGHT_TIME, file.lastModified)) {
              this.setFileInfo(file, '创建时间超过当前时间', UN_RECORD_AT_RIGHT_TIME)
              unBeforeNowFiles.push(file.file.name)
              continue
            }
          }
          if (this.isUploadFile) {
            if (!this.checkFileExtension(file.file)) {
              invalidFiles.push(file.file.name)
              this.setFileInfo(file, '文件格式错误', START_UPLOAD_CODE)
              continue
            }
          } else {
            // 获取文件的类型，图片或者视频
            file.type = getFileType(file.file);
            if (file.type === FILE_TYPE_OTHER_DESC) {
              invalidFiles.push(file.file.name)
              this.setFileInfo(file, '格式不规范', START_UPLOAD_CODE)
              continue
            }
          }
          // 检查图片和视频的文件大小是否合格
          const isOverSize = checkFileOverSize(file, this.maxImageSize, this.maxVideoSize);
          if (isOverSize) {
            this.setFileInfo(file, `${file.type}太大`, START_UPLOAD_CODE)
            overSizeFiles.push(file.file.name)
            continue
          }
          if (this.isCheckWidth) {
            const isNormalDimension = await this.checkDimensions(file, i + 1);
            if (!isNormalDimension) {
              continue;
            }
          }
          if (!isEmptyFunction(this.checkFile)) {
            const checkFile = await this.checkFile(file)
            if (!checkFile.valid) {
              continue;
            }
          }
          file.statusCode = UN_START_UPLOAD_CODE;
        }
      }
      for (let j = 0; j < this.images.length; j++) {
        const file = this.images[j];
        if (file.statusCode === UN_START_UPLOAD_CODE) {
          this.uploadSingleImg(file, j + 1);
        }
      }
      if (overSizeFiles.length || invalidFiles.length) {
        let message = overSizeFiles.length
          ? `${overSizeFiles.join(",")} 文件大小超过最大值`
          : "";
        message += invalidFiles.length
          ? `${invalidFiles.join(",")} 文件格式不规范`
          : "";
        this.$notify({
          type: "warning",
          message,
        });
      }
    },
    // 检测图片的宽度，最小宽度小于400，则提示重新上传
    async checkDimensions(file, index) {
      try {
        const { width, height, duration } = await getMediaDimensions(
          file,
          file.file.type
        );
        
        file.width = width;
        file.height = height;
        file.duration = duration;
        if (width && width < MIN_IMG_WIDTH) {
          Toast.fail(
            `第${index}张${file.type}宽度小于${MIN_IMG_WIDTH}px，请重新选择`
          );
          file.status = "failed";
          file.message = `宽度小于${MIN_IMG_WIDTH}`;
          file.statusCode = START_UPLOAD_CODE;
          sentryReportMessage('文件宽度小于400', 'info', {
            width,
            height,
            fileName: file?.file.name
          })
          return false;
        }
      } catch (err) {
        Toast.fail(
          `第${index}张${file.type}宽度小于${MIN_IMG_WIDTH}px，请重新选择`
        );
        file.status = "failed";
        file.message = `宽度小于${MIN_IMG_WIDTH}`;
        file.statusCode = START_UPLOAD_CODE;
        sentryReportError(err)
        return false;
      }
      return true;
    },
    // 图片压缩，压缩失败则返回返图
    async imgCompress(file) {
      let uploadImg = file.file;
      try {
        this.zipingCount += 1;
        // 同一时间只能对一张图片进行压缩
        if (this.zipingCount > 1) return;
        file.message = "压缩中...";
        uploadImg = await compressFileImage(uploadImg);
        file.message = "上传中...";
        // 如果压缩后图片小于1M 则压缩异常，继续上传原图
        if (uploadImg.size < 1024 * 1024) {
          uploadImg = file.file;
        }
        // 上报压缩结果
        this.zipingCount = 0;
      } catch (err) {
        uploadImg = file.file;
        this.zipingCount = 0;
      }
      return uploadImg;
    },
    async uploadSingleImg(file) {
      let uploadFile = file.file;

      if (
        file.type === FILE_TYPE_IMAGE_DESC &&
        file.file.size > ZIP_FILE_SIZE
      ) {
        const compressImg = await this.imgCompress(file);
        if (!compressImg) return;
        uploadFile = compressImg;
      }
      if (this.uploadingConut >= MAX_UPLOADING_COUNT) return;
      !file.retryTimes && (this.uploadingConut += 1);
      file.statusCode = START_UPLOAD_CODE;
      file.status = "uploading";
      file.message = "上传中...";
      file.file = uploadFile;
      file.uploadStartTime = Date.now();

      const { policy, signature, accessid, dir, host } = this.uploadOptions || {};
      const params = {
        policy,
        signature,
        accessid,
        OSSAccessKeyId: accessid,
        dir,
        host,
        key: `${dir}${file.file.name}`,
        success_action_status: "200",
        file: file.file,
      };
      let formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      try {
        const res = await axios({
          url: params.host,
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data;",
          },
          withCredentials: false,
        });
        if (res.status === 200) {
          this.onUploadSuccess(file, params);
        } else {
          const err = new Error('上传请求错误');
          err.name = '上传请求错误'
          sentryReportError(err, {
            dir,
            host,
            key: params.key,
            status: res.status,
          })
          this.onUploadError("上传失败", file);
        }
      } catch (e) {
        sentryReportError(e, {
          dir,
          host,
          key: params.key,
        })
        this.onUploadError(e, file);
      }
    },
    getImagesList() {
      return this.images.map(item => {
        if(this.imageType !== '') {
          item.imageType = this.imageType
        }
        return item;
      });
    },
    filePreview(currentFile) {
      if (currentFile.statusCode === UPLOAD_FAILED_CODE) {
        this.uploadSingleImg(currentFile);
        currentFile.statusCode = START_UPLOAD_CODE;
      }
    },
    // 上传成功回调
    onUploadSuccess(file, params) {
      // 根据文件名获取可访问链接
      file.uploadEndTime = Date.now();
      file.status = "done";
      file.message = "";
      file.url = `${params.host}/${params.key}`;
      file.ossUrl = params.key;
      file.statusCode = COMPELTE_UPLOAD_CODE;
      file.file.saveStatusTxt = "待提交";
      this.uploadingConut -= 1;
      this.uploadImgs();
    },
    // 上传失败回调
    onUploadError(err, file) {
      // 如果上传失败自动发起重试，重试2次还是失败则提示超时
      if (file.retryTimes >= 2) {
        this.uploadingConut -= 1;
        this.uploadImgs();
        file.status = "failed";
        file.message = "失败重传";
        file.statusCode = UPLOAD_FAILED_CODE;
        file.uploadEndTime = Date.now();
        if (file.uploadStartTime && file.uploadEndTime) {
          Toast.fail(`网络环境较差，请检查网络环境`);
        }
        return;
      }
      setTimeout(() => {
        file.retryTimes += 1;
        this.uploadSingleImg(file);
      }, 2000);
    },
  },
};
</script>

<style rel="stylesheet/less" lang="less">
.van-uploader__upload {
  width: 100%;
  height: 200px;
  border-radius: 20px;
}
.van-uploader {
  width: 100%;
}
.van-uploader__preview,
.van-uploader__upload {
  margin: 0 0.2rem 0.16rem 0.2rem;
}
.van-uploader__upload-text {
  padding: 0 15px;
}
.van-uploader__mask {
  background-color: rgba(50,50,51,0)
}
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}

.small-btn .van-uploader__upload {
  height: 80px;
  border-radius: 10px;
}
.plus-btn .van-uploader__upload {
  width: 100px;
  height: 100px;
}
.bg-color {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(50,50,51,0.88);
  &.blue-color {
    background: rgb(82, 111, 218);
  }
  &.red-color {
    background: rgb(230, 103, 99);
  }
}
.add-btn {
  width: 80px;
  height: 80px;
  margin: 0 0.2rem 0.16rem 0.2rem;
}
.disabled-text {
  width: calc(100vw - 70px);
  text-align: center;
  height: 80px;
  line-height: 80px;
}
</style>

const MIN_COMPRESS_IMG_WIDTH = 400 // 压缩的图片的最小宽度
const MAX_CANVAS_AREA = 16000000
export const convertBase64ToBlob = base64 => {
  const base64Arr = base64.split(',')
  let imgType = ''
  let base64String = ''
  if (base64Arr.length > 1) {
    base64String = base64Arr[1]
    imgType = base64Arr[0].substring(base64Arr[0].indexOf(':') + 1, base64Arr[0].indexOf(';'))
  }
  // 将base64解码
  const bytes = atob(base64String)
  const bytesCode = new ArrayBuffer(bytes.length)
  // 转换为类型化数组
  const byteArray = new Uint8Array(bytesCode)
  // 将base64转换为ascii码
  for (let i = 0; i < bytes.length; i++) {
    byteArray[i] = bytes.charCodeAt(i)
  }
  // 生成Blob对象（文件对象）
  return new Blob([bytesCode], { type: imgType })
}

const compress = img => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const moreCanvas = document.createElement('canvas')
  const moreCtx = moreCanvas.getContext('2d')
  let height = img.height
  let width = img.width
  // 根据最大画布计算缩放比
  let ratio = (width * height) / MAX_CANVAS_AREA
  const sqrtRatio = Math.sqrt(ratio)
  // 保证压缩后的图片最小宽度为600
  if (ratio > 1 && width / sqrtRatio > MIN_COMPRESS_IMG_WIDTH) {
    ratio = sqrtRatio
    width = Math.ceil(width / ratio)
    height = Math.ceil(height / ratio)
  } else {
    ratio = 1
  }
  canvas.width = width
  canvas.height = height
  ctx.fillStyle = 'transparent'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  let count = Math.ceil((width * height) / 1000000)
  if (count > 1) {
    const nw = width
    const nh = Math.ceil(height / count)
    moreCanvas.width = nw
    moreCanvas.height = nh
    for (let i = 0; i < count; i++) {
      moreCtx.drawImage(img, 0, i * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
      ctx.drawImage(moreCanvas, 0, i * nh, nw, nh)
    }
  } else {
    ctx.drawImage(img, 0, 0, width, height)
  }
  const base64Str = canvas.toDataURL('image/jpeg', 0.95)
  canvas.height = 0
  canvas.width = 0
  moreCanvas.height = 0
  moreCanvas.width = 0
  return base64Str
}

export const compressFileImage = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const fileName = file.name
    reader.readAsArrayBuffer(file)
    reader.onload = ev => {
      const blob = new Blob([ev.target['result']])
      window['URL'] = window['URL'] || window['webkitURL']
      const blobURL = window['URL'].createObjectURL(blob)
      const image = new Image()
      image.src = blobURL
      image.onload = () => {
        const thumb = compress(image) // 获得的路径是将图片转换成了base64
        const blob = convertBase64ToBlob(thumb)
        const img = new File([blob], fileName, { type: 'image/jpeg' })
        resolve(img)
      }
      image.onerror = e => {
        reject(e)
      }
    }
  })
}